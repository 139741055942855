import { Button, Container, Dropdown, DropdownButton, Form, FormControl, Nav, Navbar, NavDropdown, Offcanvas, ToggleButton } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

function Main() {
  const navigate = useNavigate()
  return (
    <>
        <Container>
          <h1>Web3 Intellectual Competitions</h1>
          <Button variant="primary" role={"button"} onClick={() => navigate('/game-list')}>
            Play now!
          </Button>
        </Container>
    </>
  )
}
export default Main
