import Cookies from "js-cookie"
import { v4 as uuidv4 } from 'uuid';
import { uniqueNamesGenerator, Config, adjectives, colors, animals } from 'unique-names-generator';
import LocaleLanguages from "../enum/LocaleLanguages";

const customConfig: Config = {
  dictionaries: [adjectives, colors, animals],
  separator: ' ',
  length: 2,
};

export default class Cookie {

  private static _rootKey = 'wordle'

  public static getPlayerHash() {
    const key = 'user_hash'
    let hash = Cookie._getValue(key)
    if (hash === undefined) {
      hash = uuidv4()
      Cookie._setValue(key, hash)
    }
    return hash
  }

  public static getNickname() {
    const key = 'nickname'
    let nickname = Cookie._getValue(key)
    if (nickname === undefined) {
      nickname = uniqueNamesGenerator(customConfig)
      Cookie._setValue(key, nickname)
    }
    return nickname
  }

  public static setNickname(nickname: string) {
    return Cookie._setValue('nickname', nickname)
  }

  public static getBalance() {
    const key = 'balance'
    let balance = Cookie._getValue(key)
    if (balance === undefined) {
      balance = '0'
      Cookie._setValue(key, balance)
    }
    return Number(balance)
  }

  public static setBalance(balance: number) {
    return Cookie._setValue('balance', String(balance))
  }

  public static getLanguage(): LocaleLanguages {
    const key = 'language'
    let language = Cookie._getValue(key)
    if (language === undefined) {
      language = LocaleLanguages.English
      Cookie._setValue(key, language)
    }
    switch (language) {
      case LocaleLanguages.Russian:
        return LocaleLanguages.Russian
      case LocaleLanguages.English:
        return LocaleLanguages.English
      default:
        return LocaleLanguages.English
    }
  }

  public static setLanguage(language: LocaleLanguages) {
    Cookie._setValue('language', language)
  }

  public static getRoom() {
    const key = 'room'
    return Cookie._getValue(key)
  }

  public static setRoom(room: string) {
    return Cookie._setValue('room', room)
  }

  private static _getValue(key: string) {
    return Cookies.get(Cookie._rootKey + '_' + key)
  }

  private static _setValue(key: string, defaultValue: string) {
    Cookies.set(Cookie._rootKey + '_' + key, defaultValue, { domain: '*.spiderx.io' })
  }

}