import { useEffect, useState } from 'react'
import { Button, Container, Nav, Navbar } from 'react-bootstrap'
import Holder from 'holderjs'
import PlatformBackendV1, { Game as GameType } from '../models/PlatformBackendV1'
import Game from './GameList/Game'

export const GameList = (): JSX.Element => {
    const [games, setGames] = useState<GameType[]>([])

    useEffect(() => {
    Holder.run()
    setGames(PlatformBackendV1.getGames())
    }, [])

    return (
    <>
      <Container>
        {games.map(game => {
          return <Game game={game}/>
        })}
      </Container>
    </>
    )
}

export default GameList
