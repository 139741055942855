import { useEffect, useState } from "react"
import { Button, Card, Col, Container, Nav, Navbar, Row } from "react-bootstrap"
import Holder from 'holderjs'
import PlatformBackendV1, { Game as GameType } from "../../models/PlatformBackendV1"

function Game(props: { game: GameType }) {
  return (
    <Row key={props.game.name}>
      <Col>
        <Card className="mb-2">
        <Card.Img variant="top" src="holder.js/100px180" />
        <Card.Body>
          <Card.Title>{props.game.name}</Card.Title>
          <Card.Text>{props.game.description}</Card.Text>
          {props.game.isActive ? [
          <Button variant="primary" role={"button"} onClick={() => { window.location.href = props.game.link }}>Play</Button>,
          <Button variant="">How to play?</Button>
          ]
          :
          <Button variant="secondary" disabled>Soon</Button>
          }
        </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default Game