export type Game = {
  name: string,
  description: string,
  isActive: boolean,
  link: string,
}

export default class PlatformBackendV1 {
  public static getGames(): Game[] {
    return [
      {
        name: "Wordle",
        description: 'The first "Wordle" multiplayer with decentralized cryptocurrency bets between players!',
        isActive: true,
        link: "https://wordle.spiderx.io",
      },
      {
        name: "Chess",
        description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        isActive: true,
        link: "https://chess.spiderx.io",
      },
      {
        name: "Checkers",
        description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        isActive: false,
        link: "https://checkers.spiderx.io",
      },
      {
        name: "Pinocle",
        description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        isActive: false,
        link: "https://pinocle.spiderx.io",
      }
    ]
  }
}
